<template>
    <div class="draftContractPop">
        <header>请选择合同类型</header>
        <div class="types">
            <el-radio-group v-model="contractType">
                <el-radio label="HTPS-QY">
                    正式赊销合同
                </el-radio>
                <el-radio label="HTPS-LS1">
                    临时赊销合同
                </el-radio>
                <el-radio label="HTPS-XS">
                    现销合同
                </el-radio>
            </el-radio-group>
        </div>
        <div class="text-center">
            <el-button type="primary" @click="onSubmit">
                确定
            </el-button>
            <el-button @click="closeFn">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            contractType: 'HTPS-QY',
        };
    },
    watch: {},
    computed: {},
    methods: {
        onSubmit() {
            window.contractType = this.contractType;
            window.hideToast();
            this.$WFShowStartLayer(window.contractType, this.extr.urlParam);
        },
        closeFn() {
            window.hideToast();
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.draftContractPop
    header
        height .4rem
        background #4f67ad
        line-height .4rem
        color #fff
        text-align center
        font-size .18rem
    .types
        padding .5rem .2rem
    .text-center
        text-align center
        position absolute
        bottom .1rem
        width 100%
</style>